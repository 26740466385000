import { API_CACHE_NAME, idbResponse } from '@/api/_helpers';
import companyId from '@/api/modules/companyId';
import { broadcastMessage } from "@/helpers/broadcast";
import { toDictionary } from '@/helpers/helpers';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import LocalIdMap from '@/models/LocalIdMap';
import { openDB } from 'idb';

let _localChangesInUse = null;
export const localChangesInUse = {
	get value() { return _localChangesInUse; },
	set value(x) { _localChangesInUse = x; }
};

export async function setLocalChangesInUse(value) {
	value = !!value;
	if (value !== localChangesInUse.value) {
		localChangesInUse.value = value;
		const idb = await openIdb();
		if (idb) {
			await idb.put('localInfo', value, 'localChangesInUse');
			broadcastMessage({ key: 'localChangesInUse', value: value });
		}
	}
}

export async function refreshLocalChangesInUse() {
	const idb = await openIdb();
	localChangesInUse.value = idb && !!(await idb.get('localInfo', 'localChangesInUse'));
}

async function openIdb() {
	try {
		return await openDB('nitrogen', 3, {
			// upgrade: idb, oldVersion, newVersion, transaction
			upgrade(idb, oldVersion) {
				// Initial setup
				if (oldVersion < 1) {
					idb.createObjectStore('customers', { keyPath: 'id' });
					idb.createObjectStore('customerPayments', { keyPath: 'id' });
					idb.createObjectStore('files', { keyPath: 'id' });
					idb.createObjectStore('inspectionItems', { keyPath: 'id' });
					idb.createObjectStore('inventory', { keyPath: 'id' });
					idb.createObjectStore('paymentTerms', { keyPath: 'id' });
					idb.createObjectStore('services', { keyPath: 'id' });
					idb.createObjectStore('tankTypes', { keyPath: 'id' });
					idb.createObjectStore('users', { keyPath: 'id' });
					idb.createObjectStore('vehicles', { keyPath: 'id' });
					idb.createObjectStore('vehicleTypes', { keyPath: 'id' });
					idb.createObjectStore('wasteDisposals', { keyPath: 'id' });
					idb.createObjectStore('wasteDisposalSites', { keyPath: 'id' });
					idb.createObjectStore('wasteDisposalSiteTypes', { keyPath: 'id' });
					idb.createObjectStore('wasteTypes', { keyPath: 'id' });
					idb.createObjectStore('workOrders', { keyPath: 'id' });
					idb.createObjectStore('localChanges').createIndex("storeName", "storeName", { unique: false });
					idb.createObjectStore('localIds');
					idb.createObjectStore('localIdsMap').createIndex("storeName", "storeName", { unique: false });
					idb.createObjectStore('localInfo');
				}
				if (oldVersion < 2) {
					idb.createObjectStore('companyLocations', { keyPath: 'id' });
					idb.createObjectStore('roles', { keyPath: 'id' });
					idb.createObjectStore('vehicleMaintenanceTypes', { keyPath: 'id' });
					idb.createObjectStore('settings');
				}
				if (oldVersion < 3) {
					idb.createObjectStore('rentalItems', { keyPath: 'id' });
					idb.createObjectStore('rentalItemTypes', { keyPath: 'id' });
				}
			},
		});
	} catch (e) {
		console.error(e);
		return null;
	}
}

export async function getIdb() {
	// disable idb in pdf
	if (self.location.pathname.toLowerCase().startsWith('/pdf/')) { return null; }
	return await openIdb();
}

export async function clearDatabase(skipCompanyIdReset) {
	if (!skipCompanyIdReset) {
		companyId.set(null);
	}
	if ('caches' in self && self.caches) {
		await self.caches.delete(API_CACHE_NAME);
	}
	const idb = await openIdb();
	if (idb) {
		await Promise.all([
			idb.clear('companyLocations'),
			idb.clear('customerPayments'),
			idb.clear('customers'),
			idb.clear('files'),
			idb.clear('inspectionItems'),
			idb.clear('inventory'),
			idb.clear('localChanges'),
			idb.clear('localIds'),
			idb.clear('localIdsMap'),
			idb.clear('localInfo'),
			idb.clear('paymentTerms'),
			idb.clear('rentalItems'),
			idb.clear('rentalItemTypes'),
			idb.clear('roles'),
			idb.clear('services'),
			idb.clear('settings'),
			idb.clear('tankTypes'),
			idb.clear('users'),
			idb.clear('vehicleMaintenanceTypes'),
			idb.clear('vehicles'),
			idb.clear('vehicleTypes'),
			idb.clear('wasteDisposals'),
			idb.clear('wasteDisposalSites'),
			idb.clear('wasteDisposalSiteTypes'),
			idb.clear('wasteTypes'),
			idb.clear('workOrders'),
		]);
	}
}

// Helpers

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 */
export async function putIfExists(idb, storeName, value) {
	if (await idb.count(storeName, value.id) > 0) {
		await idb.put(storeName, value);
		return true;
	}
	return false;
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 */
export async function putIfNotExists(idb, storeName, value) {
	if (await idb.count(storeName, value.id) === 0) {
		await idb.put(storeName, value);
	}
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @param {Object[]} values
 */
export async function replaceAllExisting(idb, storeName, values) {
	const changes = await getLocalChanges(idb, storeName);
	const changesMap = toDictionary(changes);
	const returnValues = [];
	if (values.length === 0) return returnValues;
	const valueMap = toDictionary(values);
	let cursor = await idb.transaction(storeName, 'readwrite').store.openCursor();
	while (cursor) {
		if (cursor.key in changesMap) {
			// has local changes, don't update or delete idb
		} else if (cursor.key in valueMap) {
			const value = valueMap[cursor.key];
			await cursor.update(value);
			delete valueMap[cursor.key];
			returnValues.push(value);
		}
		cursor = await cursor.continue();
	}
	// for (const value of Object.values(valueMap)) {
	// 	await idb.put(storeName, value);
	// }
	return returnValues;
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @param {Object[]} values
 */
export async function replaceAll(idb, storeName, values) {
	const changes = await getLocalChanges(idb, storeName);
	const changesMap = toDictionary(changes);
	const valueMap = toDictionary(values);
	let cursor = await idb.transaction(storeName, 'readwrite').store.openCursor();
	while (cursor) {
		if (cursor.key in changesMap) {
			// has local changes, don't update or delete idb
		} else if (cursor.key in valueMap) {
			await cursor.update(valueMap[cursor.key]);
			delete valueMap[cursor.key];
		} else {
			await cursor.delete();
		}
		cursor = await cursor.continue();
	}
	for (const value of Object.values(valueMap)) {
		await idb.put(storeName, value);
	}
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @param {Function} filter
 * @param {Function} mapper
 */
export async function getFiltered(idb, storeName, filter, mapper = (x => x)) {
	const output = [];
	await iterateCursor(idb, storeName, (v, k) => {
		if (filter(v, k)) {
			output.push(mapper(v, k));
		}
	});
	return output;
}

/**
 *
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @param {Function} callback
 */
export async function iterateCursor(idb, storeName, callback) {
	let cursor = await idb.transaction(storeName).store.openCursor();
	while (cursor) {
		callback(cursor.value, cursor.key)
		cursor = await cursor.continue();
	}
}



export const idbHelpers = {
	putIfExists,
	putIfNotExists,
	replaceAllExisting,
	replaceAll,
	getFiltered,
	iterateCursor,
};



/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {LocalChange} change
 */
export async function add(idb, change) {
	const currentChange = await get(idb, change.key);
	let action = null;
	if (change.state === LocalChangeState.added) {
		// always add
		action = 'put';
	} else if (change.state === LocalChangeState.modified) {
		if (currentChange) {
			if ((currentChange.state & LocalChangeState.added) === LocalChangeState.added) {
				// avoid setting added to modified
				change.state = currentChange.state;
			} else if ((currentChange.state & LocalChangeState.modifiedIndirectly) === LocalChangeState.modifiedIndirectly) {
				change.state = LocalChangeState.modified | LocalChangeState.modifiedIndirectly;
			}
		}
		action = 'put';
	} else if (change.state === LocalChangeState.deleted) {
		if (currentChange && (currentChange.state & LocalChangeState.added) === LocalChangeState.added) {
			action = 'delete';
		} else {
			action = 'put';
		}
	} else if (change.state === LocalChangeState.modifiedIndirectly) {
		if (currentChange) {
			if ((currentChange.state & LocalChangeState.added) === LocalChangeState.added ||
				(currentChange.state & LocalChangeState.modified) === LocalChangeState.modified) {
				currentChange.state = (currentChange.state | LocalChangeState.modifiedIndirectly);
				change = currentChange;
				action = 'put';
			}
		} else {
			action = 'put';
		}
	}
	if (action === 'put') {
		await idb.put('localChanges', change, change.key);
		await broadcastUpdate(idb, currentChange, change);
	} else if (action == 'delete') {
		await idb.delete('localChanges', change.key);
		await broadcastUpdate(idb, currentChange, change);
	}
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 */
export async function clearAll(idb) {
	if (await idb.count('localChanges') > 0) {
		idb.clear('localChanges');
		await broadcastUpdate(idb);
	}
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {string} changeKey
 */
export async function deleteChange(idb, changeKey) {
	await idb.delete('localChanges', changeKey);
	const currentChange = await get(idb, changeKey);
	await broadcastUpdate(idb, currentChange);
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {string} changeKey
 */
export async function get(idb, changeKey) {
	const change = await idb.get('localChanges', changeKey);
	return change ? new LocalChange(change) : null;
}

/**
 *
 * @param {import('idb').IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @param {Number} state
 * @returns {Promise<LocalChange[]>} array of local changes
 */
export async function getLocalChanges(idb, storeName, state) {
	let data;
	if (storeName) {
		data = await idb.getAllFromIndex('localChanges', 'storeName', storeName);
	} else {
		data = await idb.getAll('localChanges');
	}
	if (state) {
		data = data.filter(x => (x.state & state) === state)
	}
	data = data.map(x => new LocalChange(x));
	return data;
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {string} storeName
 * @param {number} id
 */
export async function getDataIfChanged(idb, storeName, id) {
	const r = { response: null, data: null };
	// if idb has changes for this id, use the idb value
	const change = await get(idb, LocalChange.getKey(storeName, id));
	if (change) {
		if ((change.state & LocalChangeState.added) === LocalChangeState.added ||
			(change.state & LocalChangeState.modified) === LocalChangeState.modified ||
			(change.state & LocalChangeState.modifiedIndirectly) === LocalChangeState.modifiedIndirectly) {
			r.data = await idb.get(storeName, id) ?? null;
			if (r.data) {
				r.response = idbResponse();
			}
		} else if ((change.state & LocalChangeState.deleted) === LocalChangeState.deleted) {
			r.response = notFoundResponse();
		}
	}
	return r;
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {string} storeName
 * @param {number} id
 */
export async function isDataChanged(idb, storeName, id) {
	return await idb.count('localChanges', LocalChange.getKey(storeName, id)) > 0;
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {string} storeName
 * @param {number} id
 */
export async function getDataIfChangedError(idb, storeName, id) {
	const r = { response: null, data: null };
	// if idb has changes for this id, use the idb value
	const change = await get(idb, LocalChange.getKey(storeName, id));
	if (change) {
		if (change.error && ((change.state & LocalChangeState.added) === LocalChangeState.added || (change.state & LocalChangeState.modified) === LocalChangeState.modified)) {
			r.data = await idb.get(storeName, id) ?? null;
			if (r.data) {
				r.response = idbResponse();
			}
		} else if ((change.state & LocalChangeState.deleted) === LocalChangeState.deleted) {
			r.response = notFoundResponse();
		}
	}
	return r;
}

/**
 * Filter function to determine if an object should be included in in the result.
 *
 * @callback filterFunction
 * @param {object} x the object to be tested
 * @returns {boolean} true if x should be included in the result, otherwise falsy
 */
/**
 * Callback function to determine if an object should be included in in the result.
 *
 * @callback dataReplacementCallbackFunction
 * @param {object} target the replacement object
 * @param {object} source the object being replaced, if it exists
 * @returns {void}
 */
/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {string} storeName
 * @param {any[]} data array of data from the API
 * @param {filterFunction} filter Optional filter function to match the API filtering.
 * @param {dataReplacementCallbackFunction} callback Called before replacing an object with the replacement from IDB.
 */
export async function replaceDataIfChanged(idb, storeName, data, filter, callback) {
	if (!idb) return;
	const changesMap = toDictionary(await getLocalChanges(idb, storeName));
	const idsSet = new Set(data.map(x => x.id));
	const replacements = toDictionary(await getFiltered(idb, storeName, x => idsSet.has(x.id) && x.id in changesMap));

	for (let i = 0; i < data.length; i++) {
		const x = data[i];
		const change = changesMap[x.id];
		if (change) {
			if ((change.state & LocalChangeState.modified) === LocalChangeState.modified || (change.state & LocalChangeState.modifiedIndirectly) === LocalChangeState.modifiedIndirectly) {
				const y = replacements[x.id];
				if (y) {
					if (!(typeof filter === 'function') || filter(y)) {
						if (typeof callback === 'function') {
							callback(y, x);
						}
						data[i] = y;
					} else {
						// filter out this item
						data.splice(i, 1);
						i--;
					}
				}
			} else if ((change.state & LocalChangeState.deleted) === LocalChangeState.deleted) {
				// filter out this item
				data.splice(i, 1);
				i--;
			}
		}
	}
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 * @param {string} storeName
 * @param {any[]} data array of data from the API
 * @param {filterFunction} filter Optional filter function to match the API filtering.
 * @param {dataReplacementCallbackFunction} callback Called before replacing an object with the replacement from IDB.
 */
export async function addDataFromIdb(idb, storeName, data, localChangesOnly, filter, callback) {
	if (!idb) return;
	if (localChangesOnly) {
		const changesMap = toDictionary((await getLocalChanges(idb, storeName)).filter(x =>
			(x.state & LocalChangeState.added) === LocalChangeState.added ||
			(x.state & LocalChangeState.modified) === LocalChangeState.modified ||
			(x.state & LocalChangeState.modifiedIndirectly) === LocalChangeState.modifiedIndirectly
		));
		const f = filter;
		filter = x => x.id in changesMap && f(x);
	}
	const idbData = await getFiltered(idb, storeName, filter);
	const replace = data.length > 0;
	for (const x of idbData) {
		let i = -1;
		if (replace && (i = data.findIndex(y => y.id === x.id)) >= 0) {
			if (typeof callback === 'function') {
				callback(x, data[i]);
			}
			data[i] = x;
		} else {
			callback(x);
			data.push(x);
		}
	}
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 */
async function getCounts(idb) {
	let all = 0, direct = 0, indirect = 0, errors = 0, nonErrors = 0;
	let cursor = await idb.transaction('localChanges').store.openCursor();
	while (cursor) {
		all++;
		if (cursor.value.state === LocalChangeState.modifiedIndirectly) {
			indirect++;
		} else {
			direct++;
		}
		if (cursor.value.error) {
			errors++;
		} else {
			nonErrors++;
		}
		cursor = await cursor.continue();
	}
	return { all, direct, indirect, errors, nonErrors };
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 */
export async function getLocalChangesCount(idb) {
	return (await getCounts(idb)).direct;
}

/**
 * @param {import("idb").IDBPDatabase<DBTypes>} idb
 */
async function broadcastUpdate(idb, oldChange, newChange) {
	const counts = await getCounts(idb);
	broadcastMessage({ key: 'localChangesCount', value: counts.direct, oldChange, newChange });
	if (counts.all > 0 && counts.errors === 0) {
		if (!self.isServiceWorker && navigator.serviceWorker) {
			navigator.serviceWorker.ready.then(worker => {
				worker.active.postMessage('sync-local-changes');
			});
		}
	}
}

export const localChanges = {
	add,
	clearAll,
	deleteChange,
	get,
	getAll: getLocalChanges,
	getDataIfChanged,
	getDataIfChangedError,
	replaceDataIfChanged,
	addDataFromIdb,
	getCount: getLocalChangesCount,
};



/**
 *
 * @param {import('idb').IDBPDatabase<DBTypes>} idb
 * @param {String} storeName
 * @returns
 */
export async function getNewId(idb, storeName) {
	const tx = idb.transaction('localIds', 'readwrite');
	const store = tx.objectStore('localIds');
	let localId = await store.get(storeName);
	if (isNaN(localId)) {
		localId = -1;
	}
	// localIds uses odd negative integers so other places can use even negative integers
	localId -= 2;
	await store.put(localId, storeName);
	return localId;
}

export async function addLocalIdMap(idb, storeName, oldId, newId) {
	if (oldId < 0 && newId > 0) {
		const map = new LocalIdMap({ storeName: storeName, oldId: oldId, newId: newId });
		await idb.put('localIdsMap', map, map.key);
	}
}

export async function mapLocalId(idb, storeName, oldId) {
	if (!(oldId < 0)) { return oldId; }
	const map = await idb.get('localIdsMap', LocalIdMap.getKey(storeName, oldId))
	if (map && map.newId > 0) {
		return map.newId;
	}
	return oldId;
}

export const localIds = {
	getNewId,
	addLocalIdMap,
	mapLocalId,
};
