import { getAnyValueFromDto as getAnyValue, getValueFromDto as getValue } from './_helpers';

export default class LocalChange {
	constructor(dto) {
		this.storeName = getValue(dto, 'storeName', 'string', '');
		this.id = getValue(dto, 'id', 'number', 0);
		this.state = getValue(dto, 'state', 'number', 0);
		this.error = getAnyValue(dto, 'error') ?? null;
		this.data = getValue(dto, 'data', 'object', {});
		if (this.data === null) { this.data = {}; }
	}

	get key() {
		return LocalChange.getKey(this.storeName, this.id);
	}

	static getKey(storeName, id) {
		return `${storeName}:${id}`;
	}
}
