import { broadcast } from "@/helpers/broadcast";
import { clearDatabase, getIdb } from "@/idb";

let _companyId = null;
const companyId = {
	/**
	 * Get the companyId
	 * @returns the current company ID
	 */
	get() {
		return _companyId;
	},
	/**
	 * Set the companyId
	 * @param {Number|null} value the new company ID
	 */
	set(value) {
		let newCompanyId;
		if (typeof value === 'number' && value > 0) {
			newCompanyId = value;
		} else {
			newCompanyId = null;
		}
		const changed = _companyId !== newCompanyId;
		_companyId = newCompanyId;
		return changed;
	},
	async setFromIdb() {
		const idb = await getIdb();
		const value = idb ? (await idb.get('localInfo', 'companyId') ?? null) : null;
		return this.set(value);
	},
	async writeToIdbAndBroadcast() {
		await clearDatabase(true);
		const idb = await getIdb();
		if (idb && _companyId > 0) {
			await idb.put('localInfo', _companyId, 'companyId');
		} else if (idb) {
			await idb.delete('localInfo', 'companyId');
		}
		broadcast.postMessage({ key: 'companyId' });
	},
};

export default companyId;
