import { compare as jsonPatchCompare } from 'fast-json-patch';
import { DateTime } from 'luxon';

export function hasDtoProp(dto, property) {
	return typeof dto === 'object' && dto !== null && Object.hasOwnProperty.call(dto, property) && dto[property] !== undefined && dto[property] !== null;
}

export function getArrayFromDto(dto, property, defaultValue = []) {
	if (typeof dto === 'object' && dto !== null && Array.isArray(dto[property])) {
		return Array.from(dto[property]);
	} else {
		return defaultValue;
	}
}

export function getArrayOfObjects2FromDto(dto, property, constructor, defaultValue = []) {
	if (typeof dto === 'object' && dto !== null && Array.isArray(dto[property])) {
		const values = Array.from(dto[property]);
		for (let i = 0; i < values.length; i++) {
			values[i] = constructor(values[i]);
		}
		return values;
	} else {
		return defaultValue;
	}
}

export function getArrayOfObjectsFromDto(dto, property, classRef, defaultValue = []) {
	if (typeof dto === 'object' && dto !== null && Array.isArray(dto[property])) {
		const values = Array.from(dto[property]);
		for (let i = 0; i < values.length; i++) {
			values[i] = new classRef(values[i]);
		}
		return values;
	} else {
		return defaultValue;
	}
}

export function getObjectFromDto(dto, property, classRef, defaultValue = null) {
	if (typeof dto === 'object' && dto !== null && typeof dto[property] === 'object' && dto[property] != null) {
		return new classRef(dto[property]);
	} else {
		return defaultValue;
	}
}

export function getAnyValueFromDto(dto, property) {
	if (typeof dto === 'object' && dto !== null && property in dto) {
		return dto[property];
	}
}

export function getValueFromDto(dto, property, type, defaultValue) {
	if (typeof dto === 'object' && dto !== null && typeof dto[property] === type) {
		return dto[property];
	} else {
		return defaultValue;
	}
}

export function getDateTimeFromDto(dto, property, defaultValue) {
	if (typeof dto === 'object' && dto !== null && property in dto) {
		const value = dto[property];
		if (value instanceof DateTime && value.isValid) {
			return value;
		} if (typeof value === 'string' && value.indexOf('0001-01-01') < 0) {
			return DateTime.fromISO(dto[property]);
		} else {
			return defaultValue;
		}
	} else {
		return defaultValue;
	}
}

export function isChanged(newModel, oldModel) {
	if (newModel === null || oldModel === null) return true;
	const oldData = JSON.parse(JSON.stringify(oldModel));
	const newData = JSON.parse(JSON.stringify(newModel));
	const patch = jsonPatchCompare(oldData, newData, true);
	return patch.length > 0;
}
