import { DateTime, IANAZone, Settings as luxonSettings } from 'luxon';

export const INT_MAX_VALUE = 2147483647;

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
export const passwordOptions = {
	minLength: 6,
	maxLength: 100,
	requireNonAlphanumeric: true,
	requireDigit: true,
	requireLowercase: true,
	requireUppercase: true,
};

export const validation = {
	required: (value) => { return value !== null && typeof value === 'string' && value.trim().length > 0; },
	email: (value) => { return !validation.required(value) || emailRegex.test(value); },
	password: {
		minLength(value) { return value.length >= passwordOptions.minLength; },
		maxLength(value) { return value.length <= passwordOptions.maxLength; },
		requireNonAlphanumeric(value) { return !passwordOptions.requireNonAlphanumeric || /[^a-zA-Z0-9]+/.test(value); },
		requireDigit(value) { return !passwordOptions.requireDigit || /[0-9]+/.test(value); },
		requireLowercase(value) { return !passwordOptions.requireLowercase || /[a-z]+/.test(value); },
		requireUppercase(value) { return !passwordOptions.requireUppercase || /[A-Z]+/.test(value); },
		all(value) {
			return !validation.required(value) || (
				this.minLength(value) &&
				this.maxLength(value) &&
				this.requireNonAlphanumeric(value) &&
				this.requireDigit(value) &&
				this.requireLowercase(value) &&
				this.requireUppercase(value)
			);
		}
	},
};

export function url(path, query) {
	let url = new URL(path, self.location);
	for (const key in query) {
		if (Object.hasOwnProperty.call(query, key)) {
			let value = query[key];
			if (value instanceof DateTime) {
				value = value.isValid ? value.toISODate() : null;
			}
			if (value === null || value === undefined) {
				url.searchParams.delete(key);
			} else {
				url.searchParams.set(key, value);
			}
		}
	}
	return url;
}

export function autoExpandTextarea(textarea) {
	if (!textarea) { return; }
	let currentHeight = textarea.getBoundingClientRect().height;
	if (currentHeight > 0) {
		let offset = currentHeight - textarea.clientHeight;
		let neededHeight = textarea.scrollHeight + offset;
		if (neededHeight != currentHeight) {
			textarea.style.height = textarea.scrollHeight + offset + 'px';
		}
	} else {
		let lines = textarea.value.split('\n').length;
		if (lines != textarea.rows) {
			textarea.rows = lines;
		}
	}
}

export function autoExpandTextareaListener(e) {
	autoExpandTextarea(e.target);
}

export const autoExpandTextareaListeners = {
	'change': autoExpandTextareaListener,
	'input': autoExpandTextareaListener,
	'paste': autoExpandTextareaListener
}

export function inputDateToDateTime(dateString, timeString, isExclusive) {
	if (typeof dateString === 'string' && dateString.length > 0) {
		let dt;
		if (typeof timeString === 'string' && timeString.length > 0) {
			dt = DateTime.fromISO(dateString + 'T' + timeString);
		} else {
			dt = DateTime.fromISO(dateString);
		}
		if (isExclusive) {
			dt = dt.plus({ days: 1 });
		}
		return dt;
	}
	return null;
}

export function inputTimeToDateTime(dateTime, timeString) {
	if (!(dateTime && dateTime instanceof DateTime && dateTime.isValid)) {
		dateTime = DateTime.now().startOf('day');
	}
	if (typeof timeString === 'string' && timeString.length > 0) {
		const newDateTime = DateTime.fromISO(dateTime.toISODate() + 'T' + timeString).startOf('minute');
		if (newDateTime.isValid) {
			dateTime = newDateTime;
		}
	}
	return dateTime;
}

export function dateTimeToInputDate(input, isExclusive) {
	if (input && input instanceof DateTime) {
		if (isExclusive) {
			input = input.minus({ days: 1 });
		}
		return input.toISODate();
	}
	return '';
}

export function dateTimeToInputTime(input) {
	if (input && input instanceof DateTime) {
		return input.toISOTime({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false });
	}
	return '';
}

export function setDate(model, property, value) {
	// confirm model has property
	if (!(model !== null && typeof model === 'object' && Object.hasOwnProperty.call(model, property))) { return; }
	// if we already have a time, keep the time
	let time = '';
	if (model[property] instanceof DateTime && model[property].isValid) {
		time = dateTimeToInputTime(model[property]);
	}
	// set value
	model[property] = inputDateToDateTime(value, time);
}

export function setTime(model, property, value) {
	// confirm model has property
	if (!(model !== null && typeof model === 'object' && Object.hasOwnProperty.call(model, property))) { return; }
	// if we already have a date, set the time to midnight since we can't actually clear the time.
	if (!value && model[property] instanceof DateTime && model[property].isValid) {
		value = '00:00';
	}
	// set value
	model[property] = inputTimeToDateTime(model[property], value);
}

export function setNow(model, property) {
	// confirm model has property
	if (!(model !== null && typeof model === 'object' && Object.hasOwnProperty.call(model, property))) { return; }
	// set value
	model[property] = DateTime.now().startOf('minute');
}

export function startOfWeek(value) {
	if (value instanceof DateTime) {
		// ISO week starts on monday
		// our week starts on Sunday
		// so shift Sunday to Monday to make it work
		if (value.weekday === 7) {
			value = value.plus({ days: 1 });
		}
		// shift Monday back to Sunday to match application
		return value.startOf('week').minus({ days: 1 });
	} else {
		return value;
	}
}

export function setDatesFromRangeValue(object, value) {
	if (value && value.indexOf(',') >= 0) {
		const range = { startDate: null, endDate: null };
		const dates = value.split(',');
		const startDate = dates[0];
		const endDate = dates[1];
		if (startDate && endDate) {
			range.startDate = DateTime.fromISO(startDate);
			range.endDate = DateTime.fromISO(endDate);
		} else {
			range.startDate = null;
			range.endDate = null;
		}
		object.startDate = range.startDate;
		object.endDate = range.endDate;
	}
}

export async function appendScriptToHead(id, url) {
	if (document.getElementById(id)) { return Promise.resolve(); }
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.id = id;
		script.onabort = reject;
		script.onerror = reject;
		script.onload = resolve;
		document.head.appendChild(script);
		script.src = url;
	});
}

export function nullableInt(input) {
	let output = null;
	if (typeof input === 'string' && input.length > 0) {
		output = parseInt(input);
	}
	if (isNaN(output)) {
		output = null;
	}
	return output;
}

export function nullableFloat(input) {
	let output = null;
	if (typeof input === 'string' && input.length > 0) {
		output = parseFloat(input);
	}
	if (isNaN(output)) {
		output = null;
	}
	return output;
}

export function nullToEmpty(input) {
	return input === null ? '' : input;
}

export function vModelBoolean(input) {
	if (typeof input === 'boolean') {
		return input;
	} else if (typeof input === 'string') {
		switch (input.trim().toUpperCase()) {
			case '1':
			case 'TRUE':
			case 'YES':
			case 'ON':
				return true;
			case '0':
			case 'FALSE':
			case 'NO':
			case 'OFF':
			default:
				return false;
		}
	} else {
		return input ? true : false;
	}
}

export function adjustNumber(input, value, isIncrement) {
	let step = Math.abs(parseFloat(input.step)) || 1;
	let scale = step - Math.floor(step);
	if (scale > 0) {
		scale = (scale + '').length - 2;
	}
	let min = parseFloat(input.min);
	if (isNaN(min)) {
		min = Number.NEGATIVE_INFINITY;
	}
	let max = parseFloat(input.max);
	if (isNaN(max)) {
		max = Number.POSITIVE_INFINITY;
	}
	if (!isIncrement) {
		step = 0 - step;
	}
	value = value + step;
	if (value > max) {
		value = max;
	}
	if (value < min) {
		value = min;
	}
	value = parseFloat(value.toFixed(scale));
	return value;
}

export function getMapUrlSearchCoordinates(latitude, longitude) {
	let coordinates = latitude + ',' + longitude;
	return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(coordinates);
}

export function getMapEmbedUrlSearchCoordinates(latitude, longitude, apiKey) {
	return 'https://www.google.com/maps/embed/v1/place?key=' + encodeURIComponent(apiKey) + '&q=' + encodeURIComponent(latitude + ',' + longitude);
}

export function getMapUrlSearchAddress(location) {
	let address = location.street1 + ', ' + (location.street2 ? location.street2 + ', ' : '') + location.city + ', ' + location.state + ' ' + location.postalCode;
	return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address);
}

export function getMapUrlDirectionsCoordinates(latitude, longitude) {
	let coordinates = latitude + ',' + longitude;
	return 'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=' + encodeURIComponent(coordinates);
}

export function getMapUrlDirectionsAddress(location) {
	let address = location.street1 + ', ' + (location.street2 ? location.street2 + ', ' : '') + location.city + ', ' + location.state + ' ' + location.postalCode;
	return 'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=' + encodeURIComponent(address);
}

export function formatPercent(amount) {
	return typeof amount === 'number' ? amount.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 3 }) : '';
}

export function formatMoney(amount, removeZeroCents) {
	let v = typeof amount === 'number' ? amount.toLocaleString(undefined, { style: 'currency', currency: 'USD' }) : '';
	if (removeZeroCents) {
		v = v.replace('.00', '')
	}
	return v;
}

export function formatFileSize(fileSizeInBytes) {
	const kb = 1 << 10, mb = kb << 10, gb = mb << 10;
	let storage = '';
	if (fileSizeInBytes < kb) {
		storage = fileSizeInBytes + ' Bytes';
	} else if (fileSizeInBytes < mb) {
		storage = (fileSizeInBytes / kb).toFixed(1) + ' KB';
	} else if (fileSizeInBytes < gb) {
		storage = (fileSizeInBytes / mb).toFixed(1) + ' MB';
	} else {
		storage = (fileSizeInBytes / gb).toFixed(1) + ' GB';
	}
	return storage;
}

const cleanPhoneFormat = /^\+1\d{10}$/;
const prettyPhoneFormat = /^\(\d{3}\) \d{3}-\d{4}$/;

export function cleanPhone(input) {
	if (!input) {
		input = '';
	}
	if (cleanPhoneFormat.test(input)) {
		return input;
	}
	let cleaned = input.replace(/[^\d]/g, '');
	// +12625749400
	if (cleaned.length == 10) {
		return '+1' + cleaned;
	} else if (cleaned.length == 11) {
		return '+' + cleaned;
	} else {
		return input;
	}
}

export function formatPhone(input) {
	if (prettyPhoneFormat.test(input)) {
		return input;
	}
	input = cleanPhone(input);
	if (cleanPhoneFormat.test(input)) {
		return '(' + input.substr(2, 3) + ') ' + input.substr(5, 3) + '-' + input.substr(8);
	} else {
		return input;
	}
}

export function round(number, precision) {
	const x = parseInt('1' + ('0'.repeat(Math.max(0, precision))));
	return Math.round(number * x) / x;
}

export function copyValues(target, source) {
	for (const key in source) {
		if (Object.hasOwnProperty.call(source, key) && Object.hasOwnProperty.call(target, key)) {
			target[key] = source[key];
		}
	}
	return target;
}

export function isImageFile(file) {
	return ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
}

export async function resizeImage(file) {
	return new Promise((resolve, reject) => {
		if (!isImageFile(file)) { reject(new Error('Invalid image')); }

		const img = document.createElement('img');
		const reader = new FileReader();
		reader.onload = function (e) {
			img.onload = async function () {
				let width = img.width;
				let height = img.height;
				let MAX_WIDTH = 1920;
				let MAX_HEIGHT = 1080;
				if (height > width) {
					const tmp = MAX_HEIGHT;
					MAX_HEIGHT = MAX_WIDTH;
					MAX_WIDTH = tmp;
				}
				if (width > MAX_WIDTH || height > MAX_HEIGHT) {
					const wM = width / MAX_WIDTH;
					const hM = height / MAX_HEIGHT;
					if (wM > hM) {
						width = MAX_WIDTH;
						height = Math.floor(height / wM);
					} else {
						width = Math.floor(width / hM);
						height = MAX_HEIGHT;
					}
				}

				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);

				const blob = await canvasToBlobAsync(canvas, file.type, 0.8)
				const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
				if (newFile.size < file.size) {
					resolve(newFile);
				} else {
					resolve(file);
				}
			};
			img.onerror = function (e) { reject(e); };
			img.src = e.target.result;
		};
		reader.onerror = function (e) { reject(e); };
		reader.readAsDataURL(file);
	});
}

export function canvasToBlobAsync(canvas, type, quality) {
	return new Promise((resolve, reject) => {
		try {
			canvas.toBlob(blob => {
				resolve(blob);
			}, type, quality);
		} catch (e) {
			reject(e);
		}
	});
}

export function getGeolocation() {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(pos => resolve(pos), error => reject(error), { maximumAge: 1000, enableHighAccuracy: true });
	});
}

export async function previewImage(file) {
	if (!isImageFile(file)) { throw new Error('Invalid image'); }
	return await getDataUrlFromBlob(file);
}

export function getDataUrlFromBlob(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (e) {
			resolve(e.target.result);
		};
		reader.onerror = function (e) { reject(e); };
		reader.readAsDataURL(blob);
	});
}

export function sentenceJoin(array) {
	if (array.length === 0) {
		return '';
	} else if (array.length === 1) {
		return array[0];
	} else {
		return array.slice(0, -1).join(', ') + ' and ' + array[array.length - 1];
	}
}

export function createCallbacks() {
	const callbacks = [];
	function callCallbacks(...args) {
		const results = [];
		if (callbacks.length > 0) {
			for (let i = 0; i < callbacks.length; i++) {
				results.push(callbacks[i].apply(undefined, args));
			}
		}
		return results;
	}
	function addCallback(callback) {
		if (typeof callback === 'function') {
			callbacks.push(callback);
		}
	}
	return { callCallbacks, addCallback };
}

export function escapeRegex(string) {
	return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function getRandomColor() {
	let r = Math.floor(Math.random() * 256).toString(16);
	r = r.length === 1 ? '0' + r : r;
	let g = Math.floor(Math.random() * 256).toString(16);
	g = g.length === 1 ? '0' + g : g;
	let b = Math.floor(Math.random() * 256).toString(16);
	b = b.length === 1 ? '0' + b : b;
	return '#' + r + g + b;
}

function convertHexColorToHSL(hex) {
	if (!(typeof hex === 'string' && /^#(?:[0-9A-F]{6}|[0-9A-F]{3})$/i)) {
		hex = "#000";
	}
	// Convert hex to RGB first
	let r = 0, g = 0, b = 0;
	if (hex.length == 4) {
		r = "0x" + hex[1] + hex[1];
		g = "0x" + hex[2] + hex[2];
		b = "0x" + hex[3] + hex[3];
	} else if (hex.length == 7) {
		r = "0x" + hex[1] + hex[2];
		g = "0x" + hex[3] + hex[4];
		b = "0x" + hex[5] + hex[6];
	}
	// Then to HSL
	r /= 255;
	g /= 255;
	b /= 255;
	let cmin = Math.min(r, g, b),
		cmax = Math.max(r, g, b),
		delta = cmax - cmin,
		h = 0,
		s = 0,
		l = 0;

	if (delta == 0)
		h = 0;
	else if (cmax == r)
		h = ((g - b) / delta) % 6;
	else if (cmax == g)
		h = (b - r) / delta + 2;
	else
		h = (r - g) / delta + 4;

	h = Math.round(h * 60);

	if (h < 0)
		h += 360;

	l = (cmax + cmin) / 2;
	s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
	s = +(s * 100).toFixed(1);
	l = +(l * 100).toFixed(1);

	return [h, s + '%', l + '%'];
}

export function setThemeColor(themeColor) {
	if (!themeColor) return;
	const themeColorHSL = convertHexColorToHSL(themeColor);
	document.documentElement.style.setProperty('--theme-color', themeColor);
	document.documentElement.style.setProperty('--theme-color-hsl', themeColorHSL);
	document.documentElement.style.setProperty('--theme-color-h', themeColorHSL[0]);
	document.documentElement.style.setProperty('--theme-color-s', themeColorHSL[1]);
	document.documentElement.style.setProperty('--theme-color-l', themeColorHSL[2]);
}

export const BILLING_TIME_ZONE = 'America/Chicago';

export function setLuxonTimeZone(timeZone) {
	if (timeZone && IANAZone.isValidZone(timeZone)) {
		luxonSettings.defaultZone = timeZone;
	} else if (IANAZone.isValidZone(BILLING_TIME_ZONE)) {
		luxonSettings.defaultZone = BILLING_TIME_ZONE;
	} else {
		luxonSettings.defaultZone = 'system';
	}
}

export function debounce(func, wait) {
	// simplified version of debounce from underscore.js
	let timeout, previous, args, result;

	const later = function () {
		const passed = Date.now() - previous;
		if (wait > passed) {
			timeout = setTimeout(later, wait - passed);
		} else {
			timeout = null;
			result = func.apply(null, args);
			if (!timeout) args = null;
		}
	};

	const debounced = function (..._args) {
		args = _args;
		previous = Date.now();
		if (!timeout) {
			timeout = setTimeout(later, wait);
		}
		return result;
	};

	debounced.cancel = function () {
		clearTimeout(timeout);
		timeout = args = null;
	};

	return debounced;
}

export function toDictionary(array, keySelector) {
	if (!(typeof keySelector === 'function')) {
		keySelector = x => x.id;
	}
	const dictionary = array.reduce((o, x) => { o[keySelector(x)] = x; return o; }, {});
	return dictionary;
}

export function toCamelCase(string) {
	if (!(typeof string === 'string')) { return string; }
	// string = string.trim();
	if (!string) return string;
	return string.substring(0, 1).toLowerCase() + string.substring(1);
}

/**
 * Returns a function used to sort elements of an array in ascending order. To sort items in descending order, swap the parameters passsed to the returned function.
 * @param {string} prop object property used to sort by
 */
export function makeComparator(...props) {
	return (a, b) => {
		for (const prop of props) {
			if (a[prop] !== b[prop]) {
				return a[prop] < b[prop] ? -1 : 1;
			}
		}
		return 0;
	}
}
