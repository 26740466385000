export const broadcast = new BroadcastChannel('sync');

/**
 * Broadcast a message to all listeners, including this tab.
 * @param {any} message
 */
export function broadcastMessage(message) {
	if (self.isServiceWorker) {
		broadcast.postMessage(message);
	} else {
		const b = new BroadcastChannel('sync');
		b.postMessage(message);
		b.close();
	}
}
