let _token = '';

export default {
	/**
	 * Get the PDF document auth token
	 * @returns the token value
	 */
	get() {
		return _token;
	},
	/**
	 * Set the PDF document auth token
	 * @param {string} value the token value
	 */
	set(value) {
		if (typeof value === 'string') {
			_token = value;
		} else {
			_token = '';
		}
	},
};
