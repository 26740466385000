import { DateTime } from 'luxon';

export function parseIntProp(rawValue) {
	const value = parseInt(rawValue);
	return isNaN(value) ? undefined : value;
}

export function parseBoolProp(rawValue) {
	if (rawValue === 'true') {
		return true;
	} else if (rawValue === 'false') {
		return false;
	}
}

export function parseDateTimeProp(rawValue) {
	let value = rawValue ?? '';
	if (!/^\d\d\d\d-\d\d-\d\d$/.test(value)) { value = ''; }
	value = DateTime.fromISO(value);
	return value && value.isValid ? value : undefined;
}

let _dynamicImportError = undefined;
export function handleImportError(error) {
	_dynamicImportError = error;
}
export function handleRouterError(error, to, from) {
	if (from.fullPath === '/' || to.fullPath === from.fullPath || to.fullPath === window.location.pathname + window.location.search + window.location.hash) { return; }
	if (
		// error thrown by vue router component loader
		(error instanceof Error && error.message.startsWith('Couldn\'t resolve component'))
		||
		// error thrown by vite preload
		(error instanceof Error && error.message.startsWith('Unable to preload CSS for'))
		||
		// actual dynamic import error
		(error && error === _dynamicImportError)
	) {
		// router couldn't dynamically import route component or asset; navigate directly to the new URL in case a deployment occurred and new assets are available
		window.location.assign(to.fullPath);
	}
}
