import { createCallbacks } from '@/helpers/helpers';

const { callCallbacks, addCallback } = createCallbacks();

export default {
	/**
	 * Call the auth callbacks.
	 * @param {Response} response Fetch API response
	 */
	callCallbacks,
	/**
	 * Add an auth callback function
	 * @param {function} callback the callback function, receives the response
	 */
	addCallback,
};
